import React from "react"
import { Link } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

import './works.scss'

const Works = () => (
  <Layout>
    <SEO title="Works" />
    <div className='works'>
      <h1>Works</h1>
      <p>This is something that I made.</p>
      <ul>
        <li>
          <Link to='/works/rts' className='rts'>
            <div className='img'>RTS</div>
            <div className='title'>Real-Time File Transfer</div>
          </Link>
        </li>
        <li>
          <Link to='/works/osaifu' className='osaifu'>
            <div className='img'>おさいふ</div>
            <div className='title'>ふたりでつける家計簿アプリ</div>
          </Link>
        </li>
      </ul>
    </div>
    <div className='back-to-home'><Link to='/'>Home</Link></div>
  </Layout>
)

export default Works
